<template>
  <header id="header" class="pt-6 pt-md-10 pb-md- pb-lg-1">
    <b-container>
      <div
        class="
          d-flex
          align-items-center
          justify-content-center
          position-relative
        "
      >
        <div class="logo mr-lg-20">
          <b-link to="/"
            ><b-img
              :src="
                require('@/esf_thehague_fietskledingvoordeel/assets/FietskledingVoordeelNL_1-opwit.png')
              "
              :srcset="
                require('!!url-loader?limit=0!@/esf_thehague_fietskledingvoordeel/assets/FietskledingVoordeelNL_1-opwit.png') +
                ' 253w ,' +
                require('!!url-loader?limit=0!@/esf_thehague_fietskledingvoordeel/assets/FietskledingVoordeelNL_1-opwit.png') +
                ' 175w, ' +
                require('!!url-loader?limit=0!@/esf_thehague_fietskledingvoordeel/assets/FietskledingVoordeelNL_1-opwit.png') +
                ' 75w '
              "
              width="320"
              height="57"
              alt="Logo"
            ></b-img>
          </b-link>
        </div>
        <div
          class="
            d-none d-lg-flex
            align-items-center
            justify-content-end
            search-and-icons
          "
        >
          <HeaderSearch />
          <HeaderTopIcons showType="O" />
        </div>
      </div>
      <HeaderSeparator />
      <div class="d-flex pt-1 pt-lg-0 position-relative">
        <Navbar />
        <div
          class="
            d-flex d-lg-none
            align-items-center
            justify-content-end
            search-and-icons
            pt-6
          "
        >
          <HeaderSearch />
          <HeaderTopIcons showType="M" />
        </div>
      </div>
    </b-container>
    <DiscountCode />
  </header>
</template>

<script>
import HeaderSearch from "@/base/core/components/HeaderSearch";
import HeaderTopIcons from "@/base/core/components/HeaderTopIcons";
import HeaderSeparator from "@/base/core/components/HeaderSeparator";
import Navbar from "@/base/core/components/Navbar";
import DiscountCode from "@/base/core/components/DiscountCode";
export default {
  name: "Header",
  components: {
    HeaderSearch,
    HeaderTopIcons,
    HeaderSeparator,
    Navbar,
    DiscountCode,
  },
  data() {
    return {};
  },
};
</script>

