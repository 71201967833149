<template>
  <div v-if="!isCookieAgreed" class="cookie-bar-wrap">
    <b-container>
      <div class="cookie-wrap">
        <div class="img">
          <b-img
            :src="
              require('@/esf_thehague_fietskledingvoordeel/assets/FietskledingVoordeelNL_1-opwit.png')
            "
            :srcset="
              require('!!url-loader?limit=0!@/esf_thehague_fietskledingvoordeel/assets/FietskledingVoordeelNL_1-opwit.png') +
              ' 253w ,' +
              require('!!url-loader?limit=0!@/esf_thehague_fietskledingvoordeel/assets/FietskledingVoordeelNL_1-opwit.png') +
              ' 175w, ' +
              require('!!url-loader?limit=0!@/esf_thehague_fietskledingvoordeel/assets/FietskledingVoordeelNL_1-opwit.png') +
              ' 75w '
            "
            width="320"
            height="57"
            alt="Logo"
          ></b-img>
        </div>
        <div class="text">
          <strong class="title">{{ $t("cookiebar_title") }}</strong>
          <p>
            {{ $t("cookiebar_text") }}
          </p>
          <div class="sub-wrap">
            <div class="checkbox-block">
              <label>
                <input
                  type="checkbox"
                  checked="checked"
                  name="check01"
                  disabled
                />
                <span class="fake-check">{{ $t("cookiebar_necessary") }}</span>
              </label>
              <label>
                <input type="checkbox" v-model="allCookies" name="check02" />
                <span class="fake-check">{{ $t("cookiebar_other") }}</span>
              </label>
            </div>
            <div class="btn-wrap">
              <b-link class="btn btn-primary text-white" @click="onClick">{{
                $t("cookiebar_accept")
              }}</b-link>
            </div>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "CookieBarWrap",
  components: {},
  data() {
    return {
      allCookies: true,
      isCookieAgreed: this.$cookies.isKey("websiteCookie"),
    };
  },
  mounted() {
    this.setGoogleConsent("denied");
    this.$gtm.enable(true);
    if (this.allCookies && this.isCookieAgreed) {
        this.updateGoogleConsent("granted");
    } else {
        this.updateGoogleConsent("denied");
    }
  },
  methods: {
    onClick() {
      this.isCookieAgreed = true;
      this.$cookies.config("180d");
      this.$cookies.set("websiteCookie", true);
        if (this.allCookies == true) {
          this.updateGoogleConsent("granted");
        } else {
            this.updateGoogleConsent("denied");
        }
    },
    
    setGoogleConsent(consent) { 
        if (typeof window.gtag !== 'function') {
            window.gtag = function() { 
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push(arguments); 
            }
        }
        
        window.gtag("consent", 'default', this.formatConsent(consent));
    },
    
    updateGoogleConsent(consent) {
        window.gtag("consent", 'update', this.formatConsent(consent));
    },
    
    formatConsent(consent) {
        return {
            ad_storage: consent,
            analytics_storage: consent,
            functionality_storage: consent,
            personalization_storage: consent,
            security_storage: "granted",
            ad_user_data: consent,
            ad_personalization: consent,
            wait_for_update: 2000,
        }
    }
  },
};
</script>
