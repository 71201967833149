<template>
  <div id="wrapper">
    <Header />
    <main id="main" :class="wrapperClass" :style="mainStyle">
      <slot></slot>
    </main>
    <Footer />
    <ClientOnly>
      <CookieBarWrap />
    </ClientOnly>
  </div>
</template>

<script>
import Header from "@/esf_thehague_fietskledingvoordeel/core/components/Header";
import Footer from "@/esf_thehague_fietskledingvoordeel/core/components/Footer";
import CookieBarWrap from "@/esf_thehague_fietskledingvoordeel/core/components/CookieBar";
import ClientOnly from "vue-client-only";

export default {
  name: "BaseLayout",
  components: {
    Header,
    Footer,
    CookieBarWrap,
    ClientOnly,
  },
  props: {
    wrapperClass: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      mainStyle: {
        'padding-top': '0px'
      }
    };
  },
  mounted() {
    this.resizeWindow();
    window.addEventListener("resize", this.resizeWindow);
  },
  methods: {
    resizeWindow() {
	let windowWidth = window.innerWidth;//document.querySelector('#width');
        if (windowWidth > 992) {
          this.mainStyle['padding-top'] = '0px';
	} else {
          let header = document.getElementById('header');
          this.mainStyle['padding-top'] = header.offsetHeight + 'px';
        }
    }
  }
};
</script>

